import Vue from "vue";
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
    key: 'ioroomer',
    storage: window.localStorage
})

export const store = new Vuex.Store({
    state: {
        demoMode: 0,
        page: 1,
        search: ''
    },
    getters: {
        getDemoMode: state => {
            return state.demoMode
        },
        getPage: state => {
            return state.page
        },
        getSearch: state => {
            return state.search
        }
    },
    mutations: {
        pushDemoModeToState(state, {demoMode}) {
            state.demoMode = demoMode
        },
        pushPageToState(state, {page}) {
            state.page = page
        },
        pushSearchToState(state, {search}) {
            state.search = search
        }
    },
    plugins: [vuexLocalStorage.plugin]
})

