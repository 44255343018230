import axios from 'axios';

export default {
    fetchProducts (params) {
        return axios.get('data/products', { params })
    },

    getProduct (id) {
        return axios.get('data/product/' + id)
    },

    updateProduct (id, data) {
        return axios.put('data/product/' + id, data)
    },

    getSimilarProducts (id, data) {
        return axios.get('data/similarproducts/' + id, data)
    },

    generateNormalMaps (id, data) {
        return axios.post('data/generatenormalmaps/' + id, data)
    },

    deleteNormalMaps (id) {
        return axios.post('data/deletenormalmaps/' + id)
    }
};