<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="4"
            >
                <v-card outlined>
                    <v-card-text class="pa-0">
                        <v-skeleton-loader
                            type="image"
                            tile="true"
                            :loading="initLoading"
                            min-height="400"
                        >
                            <VueCompareImage :leftImage="compareLeftImage" :rightImage="compareRightImage" />
                        </v-skeleton-loader>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-skeleton-loader
                                type="button"
                                tile="true"
                                :loading="initLoading"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            icon
                                            v-on="on"
                                            @click.stop="showProductInfo = !showProductInfo"
                                    >
                                        <v-icon>info</v-icon>
                                    </v-btn>
                                </template>
                                <span>Produktinformationen</span>
                            </v-tooltip>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="button"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon
                                        v-on="on"
                                        @click.stop="fullscreenCompareDialog = true"
                                    >
                                        <v-icon>fullscreen</v-icon>
                                    </v-btn>
                                </template>
                                <span>Vollansicht</span>
                            </v-tooltip>
                        </v-skeleton-loader>
                    </v-card-actions>

                    <v-expand-transition>
                        <div v-show="showProductInfo">
                            <v-divider></v-divider>

                            <v-card-text>
                                <v-row>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.manufacturer"
                                                    label="Hersteller"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.product_serie"
                                                    label="Produktserie"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.product_name"
                                                    label="Produktname"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.sku"
                                                    label="SKU"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.mpn"
                                                    label="MPN"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.ean13"
                                                    label="EAN"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                    </v-col>
                                    <v-col
                                            cols="6"
                                    >
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.height"
                                                    label="Plankenlänge (in mm)"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                        <v-skeleton-loader
                                                type="list-item-two-line"
                                                tile="true"
                                                :loading="initLoading"
                                        >
                                            <v-text-field
                                                    v-model="compareproduct.width"
                                                    label="Plankenbreite (in mm)"
                                                    color="grey darken-4"
                                                    readonly
                                            ></v-text-field>
                                        </v-skeleton-loader>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                        </div>
                    </v-expand-transition>
                </v-card>
            </v-col>

            <v-col
                cols="8"
            >
                <v-card outlined class="mb-3">
                    <v-card-text>
                        <v-row
                                align="center"
                                no-gutters
                        >
                            <v-col cols="5">
                                <v-combobox
                                        v-model="selected_product_families"
                                        :items="product_families"
                                        item-text="familylabel"
                                        item-value="family"
                                        label="Produktfamilien auswählen"
                                        dense
                                        flat
                                        solo
                                        single-line
                                        multiple
                                        chips
                                        small-chips
                                        return-object
                                        v-on:change="onChangeParams()"
                                        class="mb-0"
                                        color="grey darken-4"
                                        item-color="grey darken-4"
                                ></v-combobox>
                            </v-col>

                            <v-col cols="7">
                                <v-slider
                                        v-model="similar_tolerance"
                                        label="Distanz-Toleranz"
                                        color="red accent-3"
                                        thumb-color="grey darken-4"
                                        track-color="grey darken-4"
                                        thumb-label
                                        :min="0.1"
                                        :max="5"
                                        step="0.1"
                                        ticks
                                        class="mb-0"
                                        v-on:click="onChangeParams()"
                                        v-on:end="onChangeParams()"
                                ></v-slider>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

                <v-card outlined>
                    <v-data-table
                            :headers="headers"
                            :items="similarproducts"
                            :loading="loading"
                            :loading-text="loadingText"
                            dense
                            hide-default-footer
                    >
                        <v-progress-linear slot="progress" color="grey darken-4" indeterminate></v-progress-linear>

                        <template v-slot:item.compareimages="props">
                            <v-btn small text ripple icon :color="compareIconColor(props.item)" @click="compareImages(props.item)">
                                <v-icon>compare</v-icon>
                            </v-btn>
                        </template>

                        <template v-slot:item.shopimages="props">
                            <template v-if="props.item.shopimages != undefined">
                                <v-tooltip right dark color="white" content-class="preview-shopimage elevation-2">
                                    <template v-slot:activator="{ on }">
                                        <div class="preview-shopimage-thumb d-flex align-center">
                                            <img v-on="on" @click="tooltipOpenSrc(props.item.shopimages.previews['src'])" :src="props.item.shopimages.previews['tn-32x32']" v-if="props.item.shopimages.previews != undefined" />
                                        </div>
                                    </template>
                                    <span>
                                <img :src="props.item.shopimages.previews['tn-200x200']" v-if="props.item.shopimages.previews != undefined" />
                            </span>
                                </v-tooltip>
                            </template>
                        </template>

                        <template v-slot:item.product="props">
                            {{ props.item.brand }} {{ props.item.product_serie }} {{ props.item.product_name }}
                        </template>

                        <template v-slot:item.specularcolor="props">
                            <v-edit-dialog
                                    :return-value.sync="props.item.specularcolor"
                                    large
                                    lazy
                                    persistent
                                    @save="saveProduct(props.item)"
                                    cancel-text="Abbrechen"
                                    save-text="Speichern"
                                    color="grey darken-4"
                            >
                                {{ props.item.specularcolor }}
                                <template slot="input">
                                    <v-text-field
                                            v-model="props.item.specularcolor"
                                            label="Bearbeiten"
                                            color="grey darken-4"
                                            single-line
                                            autofocus
                                    ></v-text-field>
                                </template>
                            </v-edit-dialog>
                        </template>

                        <template v-slot:item.name="props">
                            <v-btn small text ripple icon color="grey darken-4" :to="{ name: 'EditProduct', params: { productId: props.item.id } }">
                                <v-icon>edit</v-icon>
                            </v-btn>
                        </template>

                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog
            v-model="fullscreenCompareDialog"
            max-width="900"
        >
            <v-card tile>
                <v-responsive :aspect-ratio="1/1">
                    <v-card-text class="pa-0">
                        <VueCompareImage :leftImage="compareLeftFullImage" :rightImage="compareRightFullImage" />
                    </v-card-text>
                </v-responsive>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
    import ProductsService from '../services/ProductsService'
    import VueCompareImage from 'vue-compare-image'

    export default {
        name: 'products',
        components: {
            VueCompareImage
        },
        data () {
            return {
                dialog: false,
                showProductInfo: false,
                fullscreenCompareDialog: false,
                loading: true,
                loadingText: 'Ähnliche Produkte werden gesucht ...',
                initLoading: true,
                compareproduct: [],
                comparedProduct: null,
                compareLeftImage: null,
                compareRightImage: null,
                compareLeftFullImage: null,
                compareRightFullImage: null,
                product_families: [
                    {
                        familylabel: 'Parkettboden',
                        family: 'parquet_flooring'
                    },
                    {
                        familylabel: 'Laminatboden',
                        family: 'laminate_flooring'
                    },
                    {
                        familylabel: 'Vinylboden',
                        family: 'vinyl_flooring'
                    },
                    {
                        familylabel: 'Designboden',
                        family: 'design_flooring'
                    },
                    {
                        familylabel: 'Korkboden',
                        family: 'cork_flooring'
                    }
                ],
                similar_tolerance: 3,
                selected_product_families: [],
                similarproducts: [],
                headers: [
                    {
                        text: '',
                        align: 'center',
                        value: 'compareimages',
                        sortable: false,
                        width: '40px'
                    },
                    {
                        text: '',
                        align: 'left',
                        value: 'shopimages',
                        sortable: false,
                        width: '40px'
                    },
                    {
                        text: 'Farbdistanz',
                        align: 'left',
                        value: 'color_distance',
                        sortable: false
                    },
                    {
                        text: 'Produkt',
                        align: 'left',
                        value: 'product',
                        sortable: false,
                    },
                    {
                        text: 'SKU',
                        value: 'sku',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'MPN',
                        value: 'mpn',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'Aktionen',
                        value: 'name',
                        align: 'right',
                        sortable: false,
                        width: '95px'
                    }
                ]
            }
        },
        mounted () {
            this.getProduct(this.$route.params.productId)
            this.getApiSimilarProductData(this.$route.params.productId)
        },
        computed: {

        },
        watch: {

        },
        methods: {
            onChangeParams() {
                this.getApiSimilarProductData(this.$route.params.productId)
            },
            async getProduct (id) {
                this.loading = true

                let response = null

                try {
                    response = await ProductsService.getProduct(id)

                    let product = response.data
                    this.compareproduct = product

                    if(product.shopimages.previews != undefined) {
                        this.compareLeftImage = product.shopimages.previews['tn-650x650']
                        this.compareRightImage = product.shopimages.previews['tn-650x650']
                    }

                    this.compareLeftFullImage = product.mainpreviewimage
                    this.compareRightFullImage = product.mainpreviewimage

                    this.initLoading = false

                } catch (err) {
                    this.$router.push({ path: '/' })
                }
            },
            async getApiSimilarProductData (id) {
                this.loading = true
                let params = {
                    selectedfamilies: this.selected_product_families,
                    tolerance: this.similar_tolerance
                };

                const response = await ProductsService.getSimilarProducts(id, {params: params})

                this.similarproducts = response.data.data
                this.selected_product_families = response.data.selected_product_families

                this.loading = false
            },
            tooltipOpenSrc: function(src) {
                var win = window.open(src, '_blank');
                win.focus();
            },
            compareIconColor: function(item) {
                if(item === this.comparedProduct) {return 'grey darken-4'}
                else {return 'grey lighten-1'}
            },
            compareImages: function(product) {
                this.comparedProduct = product
                if(product.shopimages.previews != undefined) {
                    this.compareRightImage = product.shopimages.previews['tn-650x650']
                }
                this.compareRightFullImage = product.mainpreviewimage
            }
        }
    }
</script>

<style scoped>
    .preview-shopimage {
        padding: 10px 10px;
    }
    .preview-shopimage-thumb {
        padding: 1px !important;
        cursor: pointer;
    }
    .preview-shopimage img {
        display: block;
    }
    .preview-shopimage.menuable__content__active {
        opacity: 1 !important;
    }
</style>