<template>
  <v-app>
    <AppDrawer :drawer="drawer" v-if="$auth.check()"></AppDrawer>
    <AppBar v-on:drawerchange="drawer = !drawer" v-if="$auth.check()"></AppBar>
    <v-main>
      <v-container fluid fill-height class="align-start grey lighten-5">
        <router-view></router-view>
      </v-container>
    </v-main>
    <AppFooter v-if="$auth.check()"></AppFooter>
  </v-app>
</template>

<script>
  import AppBar from './components/AppBar'
  import AppDrawer from './components/AppDrawer'
  import AppFooter from './components/AppFooter'

  export default {
    name: 'app',
    components: {
      AppBar,
      AppDrawer,
      AppFooter
    },
    data: () => ({
      drawer: true
    }),
    methods: {
      goBack () {
        window.history.length > 1 ? this.$router.go(-1) : this.$router.push('/')
      }
    }
  }
</script>

<style>
  html,
  body {
    padding: 0;
    margin: 0;
    height: 100%;
    width: 100%;
  }
  #appname {
    font-family: 'IBM Plex Mono', monospace;
    font-weight: 400;
    font-size: 24px;
  }
</style>
