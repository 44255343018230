import axios from 'axios';

export default {
    fetchManufacturers (params) {
        return axios.get('data/manufacturers', { params })
    },

    getManufacturer (id) {
        return axios.get('data/manufacturers/' + id)
    }
};