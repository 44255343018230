<template>
    <v-layout align-center justify-center>
        <v-flex xs12 sm8 md4>
            <v-card class="elevation-4">
                <v-toolbar color="grey darken-4"
                           dark>
                    <v-toolbar-title id="appname" class="d-inline-flex">
                        <Logo/>
                        <span class="red--text text--accent-3 pl-4">{{ appname }}</span>
                    </v-toolbar-title>
                </v-toolbar>
                <v-card-text>
                    <v-form @submit.prevent="login">
                        <v-text-field prepend-icon="person" name="login" label="Login" type="text" color="grey darken-4" v-model="username" required @keyup.enter.native="login"></v-text-field>
                        <v-text-field id="password" prepend-icon="lock" name="password" label="Password" type="password" color="grey darken-4" v-model="password" required @keyup.enter.native="login"></v-text-field>
                    </v-form>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn dark color="grey darken-4" type="submit" @click="login">Login</v-btn>
                </v-card-actions>
            </v-card>
        </v-flex>
        <v-snackbar
                v-model="error"
                color="grey darken-4"
                class="red--text text--accent-3"
                :timeout="6000"
                multiLine
                top
                right
        >
            {{ errorMessage }}
            <v-btn
                    icon
                    text
                    color="white"
                    @click="error = false"
            >
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-layout>
</template>
<script>
    import Logo from '../svg/Logo'

    export default {
        components: {
          Logo
        },
        data(){
            return {
                appname: 'I/O ROOMER',
                username: null,
                password: null,
                error: false,
                errorMessage: ''
            }
        },
        methods: {
            login(){
                this.$auth.login({
                    data: {
                        name: this.username,
                        password: this.password
                    },
                    rememberMe: true,
                    redirect: '/',
                    fetchUser: true
                })
                .then(() => {
                    // success
                }, (resp) => {
                    this.error = true
                    this.errorMessage = resp.response.data.msg
                });
            },
        }
    }
</script>
