<template>
    <v-layout justify-center align-start>
        <v-card outlined class="card--flex-toolbar">
            <v-toolbar flat light color="white">
                <v-toolbar-title class="body-2">Produkte</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-text-field
                        v-model="search"
                        append-icon="close"
                        @click:append="resetSearch"
                        label="Suche"
                        single-line
                        hide-details
                        color="grey darken-4"
                ></v-text-field>
            </v-toolbar>
            <v-divider></v-divider>
            <v-data-table
                    :headers="headers"
                    :items="products"
                    :items-per-page="pagination.rowsPerPage"
                    :loading="loading"
                    :loading-text="loadingText"
                    dense
                    hide-default-footer
            >
                <v-progress-linear slot="progress" color="grey darken-4" indeterminate></v-progress-linear>

                <template v-slot:item.shopimages="props">
                    <template v-if="props.item.shopimages != undefined">
                        <v-tooltip right dark color="white" content-class="preview-shopimage elevation-2">
                            <template v-slot:activator="{ on }">
                                <div class="preview-shopimage-thumb d-flex align-center">
                                    <img v-on="on" @click="tooltipOpenSrc(props.item.shopimages.previews['src'])" :src="props.item.shopimages.previews['tn-32x32']" v-if="props.item.shopimages.previews != undefined" />
                                </div>
                            </template>
                            <span>
                                <img :src="props.item.shopimages.previews['tn-200x200']" v-if="props.item.shopimages.previews != undefined" />
                            </span>
                        </v-tooltip>
                    </template>
                </template>

                <template v-slot:item.plankformat="props">
                    {{ props.item.height }}x{{ props.item.width }}
                </template>

                <template v-slot:item.specularcolor="props">
                    <v-edit-dialog
                        :return-value.sync="props.item.specularcolor"
                        large
                        lazy
                        persistent
                        @save="saveProduct(props.item)"
                        cancel-text="Abbrechen"
                        save-text="Speichern"
                        color="grey darken-4"
                    >
                        {{ props.item.specularcolor }}
                        <template slot="input">
                            <v-text-field
                                v-model="props.item.specularcolor"
                                label="Bearbeiten"
                                color="grey darken-4"
                                single-line
                                autofocus
                            ></v-text-field>
                        </template>
                    </v-edit-dialog>
                </template>

                <template v-slot:item.cadtex="props">
                    <v-badge
                        :color="!props.item.cadtex.length ? 'red accent-4' : 'green accent-4'"
                        :content="props.item.cadtex.length"
                        inline
                        left
                        >
                    </v-badge>
                </template>

                <template v-slot:item.name="props">
                    <v-btn small text ripple icon color="grey darken-4" :to="{ name: 'SimilarProducts', params: { productId: props.item.id } }">
                        <v-icon>filter_list</v-icon>
                    </v-btn>
                    <v-btn small text ripple icon color="grey darken-4" :to="{ name: 'EditProduct', params: { productId: props.item.id } }">
                        <v-icon>edit</v-icon>
                    </v-btn>
                </template>

            </v-data-table>

            <v-divider></v-divider>

            <v-card-actions>
                <v-pagination
                    v-model="pagination.page"
                    :length="totalPages"
                    :total-visible="12"
                    color="grey darken-4"
                    light
                ></v-pagination>
                <v-spacer></v-spacer>
                <v-select
                        v-model="pagination.rowsPerPage"
                        :items="rowsPerPageList"
                        color="grey darken-4"
                        label="Zeilen pro Seite"
                ></v-select>
            </v-card-actions>
        </v-card>

        <v-snackbar
                v-model="snackbar"
                color="white"
                :timeout="6000"
                multiLine
                top
                right
                style="color: #37474f !important"
        >
            {{ snackbarText }}
            <v-btn
                dark
                icon
                text
                color="grey darken-4"
                @click="snackbar = false"
            >
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-layout>
</template>

<script>
    import ProductsService from '../services/ProductsService'
    import debounce from 'lodash/debounce';

    export default {
        name: 'products',
        data () {
            return {
                dialog: false,
                loading: true,
                loadingText: 'Produkte werden geladen ...',
                search: this.$store.getters.getSearch,
                pagination: {
                    page: this.$store.getters.getPage,
                    rowsPerPage: 15,
                    sortBy: 'sku',
                    descending: 0,
                    totalItems: 0
                },
                totalItems: 0,
                rowsPerPageList: [
                    15,
                    25,
                    50
                ],
                products: [],
                headers: [
                    {
                        text: '',
                        align: 'left',
                        value: 'shopimages',
                        sortable: false,
                        width: '40px'
                    },
                    {
                        text: 'Produkt',
                        align: 'left',
                        value: 'product_name',
                        sortable: false,
                        width: '320px'
                    },
                    {
                        text: 'Produktserie',
                        value: 'product_serie',
                        sortable: false,
                        width: '280px'
                    },
                    {
                        text: 'Hersteller',
                        value: 'brand',
                        sortable: false
                    },
                    {
                        text: 'SKU',
                        value: 'sku',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'MPN',
                        value: 'mpn',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'DEKOR',
                        value: 'decor_number',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'EAN',
                        value: 'ean13',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'Format (mm)',
                        value: 'plankformat',
                        align: 'right',
                        sortable: false
                    },
                    {
                        text: 'Specular Color',
                        value: 'specularcolor',
                        align: 'right',
                        sortable: false
                    },
                    {
                        text: 'CAD-Texturen',
                        value: 'cadtex',
                        align: 'center',
                        sortable: false
                    },
                    {
                        text: 'Aktionen',
                        value: 'name',
                        align: 'right',
                        sortable: false
                    }
                ],
                snackbar: false,
                snackbarText: '',
                snackbarColor: '',
            }
        },
        mounted () {
            if(Object.prototype.hasOwnProperty.call(this.$route.query, "search")) {
                this.search = this.$route.query.search
                this.$store.commit('pushPageToState', { page: 1 })
                this.pagination.page = 1
                this.$store.commit('pushSearchToState', { search: this.search })
                this.$router.push(this.$route.path)
            }

            this.getApiProductData('list')
        },
        computed: {
            totalPages () {
                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
            }
        },
        watch: {
            'pagination.page' (val) {
                this.$store.commit('pushPageToState', { page: val })
                this.getApiProductData('list')
            },
            'pagination.rowsPerPage' () {
                this.getApiProductData('list')
            },
            'pagination.sortBy' () {
                this.getApiProductData('list')
            },
            'pagination.descending' () {
                this.getApiProductData('list')
            },
            search: debounce(function (val) {
                this.$store.commit('pushPageToState', { page: 1 })
                this.pagination.page = 1
                this.$store.commit('pushSearchToState', { search: this.search  })

                if(val.length > 3 || !val.length) {
                    this.getApiProductData('search')
                }
            }, 800)
        },
        methods: {
            async getApiProductData (listtype) {
                this.loading = true
                let params = this.pagination
                params.search = this.search
                params.listtype = listtype
                const response = await ProductsService.fetchProducts(params)

                this.products = response.data.data
                this.pagination.totalItems = response.data.total

                this.loading = false
            },
            async saveProduct (currentproduct) {
                this.loading = true
                const response = await ProductsService.updateProduct(currentproduct.id, currentproduct)

                if(response.data.success) {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true
                } else {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true
                }

                this.loading = false
            },
            resetSearch: function() {
                this.search = ''
            },
            tooltipOpenSrc: function(src) {
                var win = window.open(src, '_blank');
                win.focus();
            }
        }
    }
</script>

<style scoped>
    .preview-shopimage {
        padding: 10px 10px;
    }
    .preview-shopimage-thumb {
        padding: 1px !important;
        cursor: pointer;
    }
    .preview-shopimage img {
        display: block;
    }
    .preview-shopimage.menuable__content__active {
        opacity: 1 !important;
    }
</style>
