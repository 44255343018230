
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */
import Vue from 'vue'
import vuetify from './plugins/vuetify.js'
import App from './App.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import VueBarcode from '@chenfengyuan/vue-barcode'
import VueClipboard from 'vue-clipboard2'
import Router from 'vue-router'
import router from './router/router.js'
import auth from '@websanova/vue-auth'
import authBearer from '@websanova/vue-auth/drivers/auth/bearer.js'
import httpVueResource from '@websanova/vue-auth/drivers/http/axios.1.x.js'
import routerVueRouter from '@websanova/vue-auth/drivers/router/vue-router.2.x.js'
import { store } from './store/globalstore.js'

axios.defaults.baseURL = process.env.VUE_APP_API_URL

Vue.router = router
Vue.config.productionTip = false

Vue.use(VueAxios, axios)
Vue.use(Router)

Vue.component(VueBarcode.name, VueBarcode)
Vue.use(VueClipboard)

Vue.use(auth, {
  auth: authBearer,
  http: httpVueResource,
  router: routerVueRouter,
  rolesKey: 'role'
})

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.prototype.$appVersion = process.env.VUE_APP_VERSION
Vue.prototype.$appAuthor = process.env.VUE_APP_AUTHOR
Vue.prototype.$appCopyright = process.env.VUE_APP_COPYRIGHT

new Vue({
  render: h => h(App),
  vuetify,
  router,
  store,
  components: { App },
  template: '<App/>'
}).$mount('#app')
