<template>
    <v-row>
        <v-col
            v-for="(dashboarditem,i) in dashboarddata"
            :key="i"
            :cols="dashboarditem.cols"
            :md="dashboarditem.md"
        >
            <v-card
                outlined
                :loading="loading"
                min-width="100%"
                :class="{
                    'v-card-scrollable': dashboarditem.type == 'timeline'
                }"
            >
                <v-card-title>
                    {{ dashboarditem.cardtitle }}
                </v-card-title>

                <v-card-subtitle v-if="dashboarditem.cardsubtitle">
                    {{ dashboarditem.cardsubtitle }}
                </v-card-subtitle>

                <v-divider></v-divider>

                <v-card-text v-if="dashboarditem.type == 'html'" v-html="dashboarditem.content"></v-card-text>

                <v-card-text class="pt-0" v-if="dashboarditem.type == 'timeline'">
                    <v-timeline
                        dense
                        align-top
                    >
                        <v-timeline-item
                            v-for="(listitem, i) in dashboarditem.items"
                            :key="i"
                            small
                            fill-dot
                            :color="i == 0 ? 'red accent-3' : 'grey darken-4'"
                        >
                            <div class="d-block subtitle-1 font-weight-bold mb-2 pl-1 pr-1" v-html="listitem.changelogversion" />
                            <div class="d-block body-2 pa-1" v-html="listitem.changelog" />
                        </v-timeline-item>
                    </v-timeline>
                </v-card-text>

                <v-card-text v-if="dashboarditem.type == 'searchlist'">
                    <v-chip
                        v-for="(listitem, i) in dashboarditem.items"
                        :key="i"
                        :to="{ name: 'Products', query: { search: listitem.search } }"
                        link
                        label
                        text-color="grey darken-4"
                        class="ma-2"
                    >
                        {{ listitem.searchlabel }}
                    </v-chip>
                </v-card-text>

                <v-card-text v-if="dashboarditem.type == 'simpletable'">
                    <v-simple-table
                        fixed-header
                        dense
                        height="380px">
                        <template v-slot:default>
                            <thead>
                                <tr>
                                    <th v-for="tableheader in dashboarditem.headers" :key="tableheader.name" class="text-left">
                                        {{ tableheader.name }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="tableitem in dashboarditem.items" :key="tableitem.mpn">
                                    <td>{{ tableitem.name }}</td>
                                    <td>{{ tableitem.mpn }}</td>
                                    <td>
                                        <v-btn small text ripple icon color="grey darken-4" :to="{ name: 'Products', query: { search: tableitem.mpn } }">
                                            <v-icon>search</v-icon>
                                        </v-btn>
                                    </td>
                                </tr>
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card-text>
            </v-card>
        </v-col>

        <v-overlay
            :value="emptyBrowserCache"
            opacity="0.85"
            z-index="1000"
        >
            <v-alert
                type="warning"
                icon="cached"
                border="left"
                prominent
            >
                Es ist eine neue Version verfügbar. Bitte leeren Sie den Browser-Cache mit der Tastenkombination <kbd>STRG</kbd> + <kbd>UMSCHALT</kbd> + <kbd>R</kbd>.
            </v-alert>
        </v-overlay>
    </v-row>
</template>

<script>
    import DashboardService from '../services/DashboardService'

    export default {
        name: 'products',
        data () {
            return {
                loading: true,
                serverAppVersion: null,
                emptyBrowserCache: false,
                dashboarddata: []
            }
        },
        mounted () {
            this.getDashboardData()
        },
        computed: {

        },
        watch: {
            serverAppVersion: function(val) {
                if(val !== this.$appVersion) {
                    this.emptyBrowserCache = true
                }
            }
        },
        methods: {
            async getDashboardData() {
                this.loading = true
                const response = await DashboardService.fetchData()

                this.dashboarddata = response.data.data
                this.serverAppVersion = response.data.version

                this.loading = false
            }
        }
    }
</script>

<style scoped>
    .v-card.v-card-scrollable > .v-card__text {
        overflow-y: auto;
        max-height: 225px;
    }
</style>
