<template>
    <v-container fluid>
        <v-data-iterator
                :items="manufacturers"
                :items-per-page.sync="pagination.rowsPerPage"
                :page="pagination.page"
                :search="search"
                :sort-by="pagination.sortBy.toLowerCase()"
                :sort-desc="pagination.descending"
                hide-default-footer
        >
            <template v-slot:header>
                <v-toolbar
                        dark
                        class="mb-1"
                >
                    <v-text-field
                            v-model="search"
                            clearable
                            flat
                            solo-inverted
                            hide-details
                            prepend-inner-icon="search"
                            label="Suche"
                    ></v-text-field>
                    <template v-if="$vuetify.breakpoint.mdAndUp">
                        <v-spacer></v-spacer>
                        <v-select
                                v-model="pagination.sortBy"
                                flat
                                solo-inverted
                                hide-details
                                :items="keys"
                                prepend-inner-icon="sort"
                                label="Sortierung"
                        ></v-select>
                        <v-spacer></v-spacer>
                        <v-btn-toggle
                                v-model="pagination.descending"
                                mandatory
                        >
                            <v-btn
                                    large
                                    depressed
                                    :value="false"
                            >
                                <v-icon>keyboard_arrow_up</v-icon>
                            </v-btn>
                            <v-btn
                                    large
                                    depressed
                                    :value="true"
                            >
                                <v-icon>keyboard_arrow_down</v-icon>
                            </v-btn>
                        </v-btn-toggle>
                    </template>
                </v-toolbar>
            </template>

            <template v-slot:default="props">
                <v-row>
                    <v-col
                            v-for="item in props.items"
                            :key="item.name"
                            cols="12"
                            sm="6"
                            md="4"
                            lg="3"
                    >
                        <v-card outlined>
                            <div class="py-4 px-12">
                                <v-img
                                        height="80"
                                        max-height="80"
                                        contain
                                        :src="item.brand_logo"
                                ></v-img>
                            </div>
                            <v-divider></v-divider>
                            <v-card-title
                                    class="headline"
                                    v-text="item.brand_name"
                            ></v-card-title>
                            <v-card-text>
                                Test
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </template>

            <template v-slot:footer>
                <v-row class="mt-2" align="center" justify="space-between">
                    <v-col md="auto">
                        <span class="grey--text">Hersteller pro Seite</span>
                        <v-menu offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        dark
                                        text
                                        color="primary"
                                        class="ml-2"
                                        v-on="on"
                                >
                                    {{ pagination.rowsPerPage }}
                                    <v-icon>arrow_drop_down</v-icon>
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                        v-for="(number, index) in rowsPerPageList"
                                        :key="index"
                                        @click="updateItemsPerPage(number)"
                                >
                                    <v-list-item-title>{{ number }}</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </v-col>

                    <v-col md="auto">
                        <v-spacer></v-spacer>
                        <span class="mr-4 grey--text">
                            Seite {{ pagination.page }} von {{ totalPages }}
                        </span>
                        <v-btn
                                depressed
                                tile
                                dark
                                class="mr-1"
                                @click="formerPage"
                        >
                            <v-icon>chevron_left</v-icon>
                        </v-btn>
                        <v-btn
                                depressed
                                tile
                                dark
                                class="ml-1"
                                @click="nextPage"
                        >
                            <v-icon>chevron_right</v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </template>

        </v-data-iterator>
    </v-container>
</template>

<script>
    import ManufacturerService from '../services/ManufacturerService'
    //import debounce from 'lodash/debounce';

    export default {
        name: 'manufacturers',
        data() {
            return {
                dialog: false,
                loading: true,
                loadingText: 'Hersteller werden geladen ...',
                search: '',
                pagination: {
                    page: 1,
                    rowsPerPage: 12,
                    sortBy: 'name',
                    descending: 0,
                    totalItems: 0
                },
                rowsPerPageList: [
                    12,
                    24,
                    48
                ],
                manufacturers: [],
                keys: [
                    'Name',
                ],
            }
        },
        mounted () {
            this.getApiManufacturerData()
        },
        computed: {
            totalPages () {
                return Math.ceil(this.pagination.totalItems / this.pagination.rowsPerPage)
            }
        },
        methods: {
            async getApiManufacturerData() {
                this.loading = true
                let params = this.pagination
                const response = await ManufacturerService.fetchManufacturers(params)

                this.manufacturers = response.data.data
                this.pagination.totalItems = response.data.total

                this.loading = false
            },
            nextPage () {
                if (this.pagination.page + 1 <= this.totalPages) this.pagination.page += 1
            },
            formerPage () {
                if (this.pagination.page - 1 >= 1) this.pagination.page -= 1
            },
            updateItemsPerPage (number) {
                this.pagination.rowsPerPage = number
            }
        }
    }
</script>