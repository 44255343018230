<template>
    <svg xmlns="http://www.w3.org/2000/svg" width="34" height="38"><path fill="none" :stroke="logocolor" stroke-width="2" stroke-linejoin="round" d="M32.7 21.2V28l-5.8 3.4m-4.1 2.2L17 37v-6.7m0-4.5v-6.7l5.8-3.4m4.1-2.3l5.8-3.4v6.7m-31.4.1V10l5.8 3.4m4.1 2.3l5.8 3.4v6.7m0 4.5V37l-5.8-3.4m-4.1-2.3L1.3 28v-6.7m9.9-16.9L17 1l5.8 3.4m4.1 2.3l5.8 3.4-5.8 3.4m-4.1 2.2L17 19.1l-5.8-3.4m-4.1-2.3L1.3 10l5.8-3.4"/></svg>
</template>
<script>
    import colors from 'vuetify/lib/util/colors'

    export default {
        name: 'products',
        data() {
            return {
                logocolor: colors.red.accent3
            }
        }
    }
</script>