<template>
    <v-navigation-drawer
        v-model="drawer"
        app
        clipped
        class="white"
    >
        <v-list
            nav
            dense
        >
            <v-subheader>HAUPTMENÜ</v-subheader>
            <v-list-item
                v-for="navitem in navitems"
                :key="navitem.title"
                :to="navitem.route"
                link
                color="grey darken-4"
                light
            >
                <v-list-item-icon>
                    <v-icon>{{ navitem.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title v-text="navitem.title"></v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
    </v-navigation-drawer>
</template>

<script>
    export default {
        name: 'appdrawer',
        props: {
            drawer: Boolean
        },
        data: () => ({
            navitems: [
                {
                    'title': 'Dashboard',
                    'icon': 'dashboard',
                    'route': '/'
                },
                {
                    'title': 'Produkte',
                    'icon': 'view_list',
                    'route': '/products'
                },
                /*
                {
                    'title': 'Hersteller',
                    'icon': 'business',
                    'route': '/manufacturers'
                },
                */
            ]
        }),
        methods: {

        }
    }
</script>

