import Router from 'vue-router';
import Login from '../components/Login';
import Dashboard from '../components/Dashboard';
import Products from '../components/Products';
import EditProduct from '../components/EditProduct';
import SimilarProducts from '../components/SimilarProducts';
import Manufacturers from '../components/Manufacturers';

let router;

router = new Router({
    routes: [
        {
            path: '/login',
            name: 'login',
            component: Login,
            meta: {
                auth: false
            }
        },
        {
            path: '/',
            name: 'Dashboard',
            components: {
                default: Dashboard
            },
            meta: {
                auth: true
            }
        },
        {
            path: '/products',
            name: 'Products',
            components: {
                default: Products
            },
            meta: {
                auth: true
            }
        },
        {
            path: '/product/:productId',
            name: 'EditProduct',
            components: {
                default: EditProduct
            },
            meta: {
                auth: true
            }
        },
        {
            path: '/similarproducts/:productId',
            name: 'SimilarProducts',
            components: {
                default: SimilarProducts
            },
            meta: {
                auth: true
            }
        },
        {
            path: '/manufacturers',
            name: 'Manufacturers',
            components: {
                default: Manufacturers
            },
            meta: {
                auth: true
            }
        }
    ]
});

export default router;
