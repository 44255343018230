<template>
    <v-footer
        app
        color="grey darken-4"
        dark
    >
        <v-spacer></v-spacer>
        <div class="overline pa-2">
            VERSION {{ this.$appVersion }} | {{ this.$appAuthor }} - {{ this.$appCopyright }}
        </div>
    </v-footer>
</template>

<script>
    export default {
        name: 'appfooter'
    }
</script>
