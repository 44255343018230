<template>
    <v-app-bar
        app
        clipped-left
        color="grey darken-4"
        dark
    >
        <v-app-bar-nav-icon color="red accent-3" @click.stop="$emit('drawerchange')"></v-app-bar-nav-icon>

        <v-toolbar-title id="appname" class="d-inline-flex">
            <Logo/>
            <span class="red--text text--accent-3 pl-4">{{ appname }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <div class="pr-4">
            {{ $auth.user().name }}
        </div>
        <v-dialog v-model="logoutdialog" v-if="$auth.check()" persistent max-width="400">
            <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                    <v-icon>power_settings_new</v-icon>
                </v-btn>
            </template>
            <v-card>
                <v-card-title class="headline">Du möchtest schon gehen?</v-card-title>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn tile depressed @click="logoutdialog = false">Okay, ich bleibe</v-btn>
                    <v-btn color="grey darken-4" tile depressed dark @click.prevent="logout">Bis bald</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-btn icon v-if="['EditProduct', 'SimilarProducts'].indexOf($route.name) > -1" :to="{ name: 'Products' }">
            <v-icon>arrow_back</v-icon>
        </v-btn>
    </v-app-bar>
</template>

<script>
    import Logo from "../svg/Logo"

    export default {
        name: 'appbar',
        components: {
            Logo
        },
        data: () => ({
            appname: 'I/O ROOMER',
            logoutdialog: false
        }),
        methods: {
            logout: function() {
                this.$store.commit('pushDemoModeToState', { demoMode: 0 })
                this.$auth.logout()
                this.$router.push('/login')
            }
        }
    }
</script>
