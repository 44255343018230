<template>
    <v-container fluid>
        <v-row>
            <v-col
                cols="4"
            >
                <v-card outlined>
                    <v-skeleton-loader
                        type="card-heading"
                        tile="true"
                        :loading="initLoading"
                    >
                        <v-card-title>
                            Produktinfo
                        </v-card-title>
                    </v-skeleton-loader>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.manufacturer"
                                label="Hersteller"
                                color="grey darken-4"
                                readonly
                            ></v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.product_serie"
                                label="Produktserie"
                                color="grey darken-4"
                                readonly
                            ></v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.product_name"
                                label="Produktname"
                                color="grey darken-4"
                                readonly
                            >
                                <v-icon
                                    slot="append"
                                    class="v-icon--link"
                                    color="grey darken-4"
                                    v-clipboard:copy="product.product_name"
                                    v-clipboard:success="onClipboardCopy"
                                    v-clipboard:error="onClipboardError"
                                >
                                    content_copy
                                </v-icon>
                            </v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.sku"
                                label="SKU"
                                color="grey darken-4"
                                readonly
                            >
                                <v-icon
                                    slot="append"
                                    class="v-icon--link"
                                    color="grey darken-4"
                                    v-clipboard:copy="product.sku"
                                    v-clipboard:success="onClipboardCopy"
                                    v-clipboard:error="onClipboardError"
                                >
                                    content_copy
                                </v-icon>
                            </v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.mpn"
                                label="MPN"
                                color="grey darken-4"
                                readonly
                            >
                                <v-icon
                                    slot="append"
                                    class="v-icon--link"
                                    color="grey darken-4"
                                    v-clipboard:copy="product.mpn"
                                    v-clipboard:success="onClipboardCopy"
                                    v-clipboard:error="onClipboardError"
                                >
                                    content_copy
                                </v-icon>
                            </v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.decor_number"
                                label="DEKOR"
                                color="grey darken-4"
                                readonly
                            >
                                <v-icon
                                    slot="append"
                                    class="v-icon--link"
                                    color="grey darken-4"
                                    v-clipboard:copy="product.decor_number"
                                    v-clipboard:success="onClipboardCopy"
                                    v-clipboard:error="onClipboardError"
                                >
                                    content_copy
                                </v-icon>
                            </v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.ean13"
                                label="EAN"
                                color="grey darken-4"
                                readonly
                            >
                                <v-icon
                                    slot="append"
                                    class="v-icon--link"
                                    color="grey darken-4"
                                    v-clipboard:copy="product.ean13"
                                    v-clipboard:success="onClipboardCopy"
                                    v-clipboard:error="onClipboardError"
                                >
                                    content_copy
                                </v-icon>
                            </v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="image"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-layout align-start justify-start>
                                <div class="my-2">
                                    <barcode v-model="product.ean13" :options="{format: 'EAN13'}" v-if="product.ean13"></barcode>
                                </div>
                            </v-layout>
                        </v-skeleton-loader>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="4"
            >
                <v-card outlined>
                    <v-skeleton-loader
                        type="card-heading"
                        tile="true"
                        :loading="initLoading"
                    >
                        <v-card-title>
                            Plankendimensionen
                        </v-card-title>
                    </v-skeleton-loader>
                    <v-divider></v-divider>
                    <v-card-text>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.height"
                                label="Plankenlänge (in mm)"
                                color="grey darken-4"
                                readonly
                            ></v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.width"
                                label="Plankenbreite (in mm)"
                                color="grey darken-4"
                                readonly
                            ></v-text-field>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="list-item-two-line"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-text-field
                                v-model="product.whratio"
                                label="Plankenratio (L/W)"
                                color="grey darken-4"
                                readonly
                            ></v-text-field>
                        </v-skeleton-loader>
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col
                cols="4"
            >
                <v-card outlined>
                    <v-tabs
                        grow
                        background-color="transparent"
                        color="grey darken-4"
                    >
                        <v-tab>
                            3D Einstellungen
                        </v-tab>

                        <v-tab>
                            CAD-Textur
                        </v-tab>

                        <v-tab>
                            CAD-Normal-Map
                        </v-tab>

                        <v-tab-item>
                            <v-card-text>
                                <v-skeleton-loader
                                    type="list-item-two-line"
                                    tile="true"
                                    :loading="initLoading"
                                >
                                    <v-text-field
                                        label="Specular Color (in %)"
                                        color="grey darken-4"
                                        v-model="product.specularcolor"
                                        type="number"
                                        min="1"
                                        max="100"
                                    ></v-text-field>
                                </v-skeleton-loader>
                            </v-card-text>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card-text>
                                <v-list
                                    dense
                                >
                                    <v-list-item-group>
                                        <v-list-item
                                            v-for="(cadtexpath, i) in product.cadtex"
                                            :key="i"
                                            ripple
                                            @click="openTexture(cadtexpath)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon class="grey" dark>texture</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="cadtexpath"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-tab-item>

                        <v-tab-item>
                            <v-card-text>
                                <v-skeleton-loader
                                    type="list-item-two-line"
                                    tile="true"
                                    :loading="initLoading"
                                >
                                    <v-text-field
                                        label="Normal Map Scale/Strength (in %)"
                                        color="grey darken-4"
                                        v-model="normalMapsVal"
                                        type="number"
                                        min="1"
                                        max="100"
                                        step="0.01"
                                    ></v-text-field>
                                </v-skeleton-loader>
                                <v-skeleton-loader
                                    type="list-item-two-line"
                                    tile="true"
                                    :loading="initLoading"
                                >
                                    <v-select
                                        v-model="normalMapsType"
                                        :items="normalMapsTypes"
                                        item-text="nmtlabel"
                                        item-value="nmtvalue"
                                        label="Normal Map Edge Detection"
                                        color="grey darken-4"
                                    ></v-select>
                                </v-skeleton-loader>
                                <div class="mt-2" v-if="!normalMapsLoading">
                                    <v-skeleton-loader
                                        type="button"
                                        tile="true"
                                        :loading="initLoading"
                                    >
                                        <v-btn
                                            dark
                                            color="grey darken-4"
                                            @click="generateNormalMaps">
                                            <v-icon left>build</v-icon> Generieren
                                        </v-btn>
                                    </v-skeleton-loader>
                                    <v-skeleton-loader
                                        type="button"
                                        tile="true"
                                        :loading="initLoading"
                                    >
                                        <v-btn
                                            dark
                                            color="error"
                                            @click="deleteNormalMaps">
                                            <v-icon left>delete</v-icon> Löschen
                                        </v-btn>
                                    </v-skeleton-loader>
                                    <v-divider class="my-4"></v-divider>
                                </div>
                                <v-list
                                    dense
                                >
                                    <v-list-item-group>
                                        <v-list-item
                                            v-for="(cadnormalpath, i) in product.cadnormal"
                                            :key="i"
                                            ripple
                                            @click="openTexture(cadnormalpath)"
                                        >
                                            <v-list-item-icon>
                                                <v-icon class="grey" dark>texture</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="cadnormalpath"></v-list-item-title>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list-item-group>
                                </v-list>
                            </v-card-text>
                        </v-tab-item>

                    </v-tabs>
                    <v-card-actions>
                        <v-skeleton-loader
                            type="button"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-btn dark outlined color="grey darken-4" class="mr-2" @click="saveProduct">
                                <v-icon left>save</v-icon> Speichern
                            </v-btn>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="button"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-btn dark color="grey darken-4" class="mr-2" @click="previewVisible = true">
                                Preview
                            </v-btn>
                        </v-skeleton-loader>
                        <v-skeleton-loader
                            type="button"
                            tile="true"
                            :loading="initLoading"
                        >
                            <v-btn dark color="grey darken-4" @click="imageVisible = true" :disabled="product.shopimages != undefined ? false : true">
                                Shopbilder
                            </v-btn>
                        </v-skeleton-loader>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
        <v-dialog
                v-model="previewVisible"
                fullscreen
                hide-overlay
                scrollable
                transition="dialog-bottom-transition"
        >
            <v-card tile>
                <v-toolbar dense light color="white">
                    <v-toolbar-title>Preview</v-toolbar-title>
                    <v-spacer></v-spacer>

                    <v-divider vertical></v-divider>

                    <v-btn
                            icon
                            color="grey darken-4"
                            @click="reloadIframe"
                    >
                        <v-icon>autorenew</v-icon>
                    </v-btn>

                    <v-divider vertical></v-divider>

                    <v-menu
                            bottom
                            left
                            transition="slide-x-transition"
                            :close-on-content-click='false'>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                    v-on="on"
                                    icon
                                    color="grey darken-4"
                            >
                                <v-icon>settings</v-icon>
                            </v-btn>
                        </template>
                        <v-list>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch
                                            label="Debug"
                                            color="grey darken-4"
                                            v-model="previewDebug"
                                            true-value=1
                                            false-value=0
                                    ></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-switch
                                            label="Render Resize"
                                            color="grey darken-4"
                                            v-model="previewRenderResize"
                                    ></v-switch>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-text-field
                                            v-model="product.specularcolor"
                                            color="grey darken-4"
                                            class="mt-0"
                                            hide-details
                                            single-line
                                            type="number"
                                            min="1"
                                            max="100"
                                            v-on:input="debounceSpecularColorInput"
                                    ></v-text-field>
                                </v-list-item-action>
                            </v-list-item>
                            <v-list-item>
                                <v-list-item-action>
                                    <v-select
                                            color="grey darken-4"
                                            v-model="previewTileType"
                                            :items="previewTileTypes"
                                            item-text="pttlabel"
                                            item-value="pttvalue"
                                    ></v-select>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-divider vertical></v-divider>

                    <v-btn-toggle
                            v-model="previewViewType"
                            group
                    >
                        <v-btn text value="perspective" color="grey darken-4" class="ma-0">
                            <v-icon>3d_rotation</v-icon>
                        </v-btn>
                        <v-btn text value="top" color="grey darken-4" class="ma-0">
                            <v-icon>vertical_align_top</v-icon>
                        </v-btn>
                    </v-btn-toggle>

                    <v-divider vertical></v-divider>

                    <v-btn icon light color="grey darken-4" @click.native="previewVisible = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-container
                    fluid
                    class="fill-height ma-0 pa-0"
                >
                    <iframe id="preview-iframe" :src="previewSrcUrl" v-if="product" ref="previewIframe" frameborder="0" allowfullscreen></iframe>
                </v-container>
            </v-card>
        </v-dialog>
        <v-dialog
                v-model="imageVisible"
                fullscreen
                hide-overlay
                scrollable
                transition="dialog-bottom-transition"
        >
            <v-card tile>
                <v-toolbar dense light color="white">
                    <v-toolbar-title>Shopbilder</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-divider vertical></v-divider>
                    <v-btn icon light color="grey darken-4" @click.native="imageVisible = false">
                        <v-icon>close</v-icon>
                    </v-btn>
                </v-toolbar>
                <v-container class="fill-only-height align-top ma-0" fluid v-if="product.shopimages && imageVisible">
                    <v-row
                        align="start"
                        wrap
                    >
                        <v-col cols="3"
                                relative
                                v-for="(image,i) in product.shopimages['images']"
                                :key="i"
                        >
                            <v-card outlined>
                                <v-img
                                        max-width="650"
                                        :src="image['thumbnails'][2]"
                                >
                                </v-img>

                                <v-card-actions>
                                    <v-btn
                                            @click="openTexture(image.src)"
                                            color="grey darken-4"
                                            dark
                                    >
                                        <v-icon left>zoom_in</v-icon>
                                        Original
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>
        </v-dialog>

        <v-snackbar
                v-model="snackbar"
                color="white"
                :timeout="6000"
                multiLine
                top
                right
                style="color: #37474f !important"
        >
            {{ snackbarText }}
            <v-btn
                    dark
                    icon
                    text
                    color="grey darken-4"
                    @click="snackbar = false"
            >
                <v-icon>close</v-icon>
            </v-btn>
        </v-snackbar>
    </v-container>
</template>

<script>
    import ProductsService from '../services/ProductsService'
    import colors from 'vuetify/lib/util/colors'
    import debounce from 'lodash/debounce'

    export default {
        name: 'editproduct',
        data: () => ({
            initLoading: true,
            loading: true,
            activeTab: '3d-settings',
            previewViewType: 'perspective',
            previewDebug: 0,
            previewRenderResize: false,
            previewSrcUrl: "",
            shopimageurl: "",
            previewVisible: false,
            imageVisible: false,
            previewTileType: 'english',
            previewTileTypes: [
                { pttlabel: 'Englischer Verband', pttvalue: 'english' },
                { pttlabel: 'Drittelversatz', pttvalue: 'third' },
                { pttlabel: 'Viertelversatz', pttvalue: 'quarter' }
            ],
            normalMapsVal: 25,
            normalMapsType: "FILTER_SOBEL_3x3",
            normalMapsTypes: [
                { nmtlabel: 'Sobel 3x3', nmtvalue: 'FILTER_SOBEL_3x3' },
                { nmtlabel: 'Sobel 5x5', nmtvalue: 'FILTER_SOBEL_5x5' },
                { nmtlabel: 'Prewitt 3x3', nmtvalue: 'FILTER_PREWITT_3x3' },
                { nmtlabel: 'Prewitt 5x5', nmtvalue: 'FILTER_PREWITT_5x5' }
            ],
            normalMapsLoading: false,
            snackbar: false,
            snackbarText: '',
            snackbarColor: '',
            product: []
        }),
        mounted() {
            this.getProduct(this.$route.params.productId)
        },
        computed: {
          previewurl: function () {
              return location.protocol+'//'+location.host+'/render/'+this.product.sku+'?ldbg='+escape(colors.grey.darken4)+'&ldcl='+escape(colors.red.accent3)+'&previewspecularcolor='+this.product.specularcolor+'&view='+this.previewViewType+'&previewdebug='+this.previewDebug+'&tileoffset='+this.previewTileType
          }
        },
        watch: {
            previewVisible: function (val) {
                if(val) {
                    if(this.previewSrcUrl != this.previewurl) {
                        this.previewSrcUrl = this.previewurl
                    }
                }
            },
            previewViewType: function () {
                if(this.previewSrcUrl != this.previewurl) {
                    this.previewSrcUrl = this.previewurl
                }
            },
            previewTileType: function () {
                if(this.previewSrcUrl != this.previewurl) {
                    this.previewSrcUrl = this.previewurl
                }
            },
            previewDebug: function () {
                if(this.previewSrcUrl != this.previewurl) {
                    this.previewSrcUrl = this.previewurl
                }
            },
            previewRenderResize: function (val) {
                if(val) {
                    this.$refs.previewIframe.setAttribute("style","width:"+this.$refs.previewIframe.clientHeight+"px !important")
                } else {
                    this.$refs.previewIframe.style.cssText = ""
                }
            },
        },
        methods: {
            async getProduct (id) {
                this.loading = true

                let response = null

                try {
                    response = await ProductsService.getProduct(id)

                    let product = response.data

                    this.product = product
                    this.product.whratio = (this.product.height / this.product.width).toFixed(4)

                    this.previewSrcUrl = this.previewurl

                    this.loading = false
                    this.initLoading = false
                } catch (err) {
                    this.$router.push({ path: '/' })
                }
            },
            async saveProduct () {
                this.loading = true
                const response = await ProductsService.updateProduct(this.product.id, this.product)

                if(response.data.success) {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true
                } else {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true
                }

                this.loading = false
            },
            async generateNormalMaps () {
                this.product.cadnormal = []

                this.normalMapsLoading = true
                const response = await ProductsService.generateNormalMaps(this.product.id, {'strength': this.normalMapsVal, 'filter': this.normalMapsType})

                if(response.data.success) {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true

                    this.product.cadnormal = response.data.data

                    this.reloadIframe()
                    this.normalMapsLoading = false
                } else {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true

                    this.normalMapsLoading = false
                }
            },

            async deleteNormalMaps () {
                this.normalMapsLoading = true
                const response = await ProductsService.deleteNormalMaps(this.product.id)

                if(response.data.success) {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true

                    this.product.cadnormal = []

                    this.reloadIframe()
                    this.normalMapsLoading = false
                } else {
                    this.snackbarColor = 'grey darken-4'
                    this.snackbarText = response.data.message
                    this.snackbar = true

                    this.normalMapsLoading = false
                }

            },

            reloadIframe: function() {
                this.$refs.previewIframe.contentDocument.location.reload(true)
            },

            openTexture: function(src) {
                var win = window.open(src, '_blank');
                win.focus();
            },

            onClipboardCopy: function(e) {
                this.snackbarColor = 'grey darken-4'
                this.snackbarText = 'Zwischenablage: ' + e.text
                this.snackbar = true
            },

            onClipboardError: function(e) {
                this.snackbarColor = 'red'
                this.snackbarText = 'Fehlerhafter Kopiervorgang: ' + e.text
                this.snackbar = true
            },

            debounceSpecularColorInput: debounce(function () {
                this.previewSrcUrl = this.previewurl
            }, 2000)
        }
    }
</script>
<style scoped>
    .fill-only-height {
        height: 100% !important;
    }
    #preview-iframe {
        width: 100%;
        height: 100%;
    }
    #shopimage {
        max-width: 650px;
    }
    .settings-card-footer {
        margin:0 -20px;
        padding:20px 20px 0 20px;
        border-top:1px solid #ebeef5;
    }
    .custom-loader {
         animation: loader 1s infinite;
         display: flex;
     }
    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }
</style>
